const enableStatistic = process.env.NODE_ENV !== 'production' || typeof CSSINJS_STATISTIC !== 'undefined';
let recording = true;
/**
 * This function will do as `Object.assign` in production. But will use Object.defineProperty:get to
 * pass all value access in development. To support statistic field usage with alias token.
 */
export function merge(...objs) {
    /* istanbul ignore next */
    if (!enableStatistic) {
        return Object.assign({}, ...objs);
    }
    recording = false;
    const ret = {};
    objs.forEach((obj) => {
        const keys = Object.keys(obj);
        keys.forEach((key) => {
            Object.defineProperty(ret, key, {
                configurable: true,
                enumerable: true,
                get: () => obj[key],
            });
        });
    });
    recording = true;
    return ret;
}
/** @internal Internal Usage. Not use in your production. */
export const statistic = {};
/** @internal Internal Usage. Not use in your production. */
// eslint-disable-next-line camelcase
export const _statistic_build_ = {};
/* istanbul ignore next */
function noop() { }
/** Statistic token usage case. Should use `merge` function if you do not want spread record. */
export default function statisticToken(token) {
    let tokenKeys;
    let proxy = token;
    let flush = noop;
    if (enableStatistic) {
        tokenKeys = new Set();
        proxy = new Proxy(token, {
            get(obj, prop) {
                if (recording) {
                    tokenKeys.add(prop);
                }
                return obj[prop];
            },
        });
        flush = (componentName, componentToken) => {
            var _a;
            statistic[componentName] = {
                global: Array.from(tokenKeys),
                component: Object.assign(Object.assign({}, (_a = statistic[componentName]) === null || _a === void 0 ? void 0 : _a.component), componentToken),
            };
        };
    }
    return { token: proxy, keys: tokenKeys, flush };
}
